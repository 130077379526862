<template>
  <OppTable
    heading="Recent Opportunities"
    :opps="opps"
    :show-table-header="false"
    :show-pagination="false"
    date-label="Created"
    date-field="created_at"
   />
</template>

<script>

// Components
import OppTable from '@/components/widgets/Opps/OppTable'

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapActions } = createNamespacedHelpers('publicists')

export default {
  name: 'PublicistOpps',
  // -------------
  // Components ==
  // -------------
  components: {
    OppTable
  },
  // -----------
  // Computed ==
  // -----------
  computed: {
    ...mapState([
      'opps'
    ])
  },
  // ----------
  // Methods ==
  // ----------
  methods: {
    ...mapActions([
      'fetchOpps'
    ])
  }
}
</script>
