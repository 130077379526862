<template>

  <!-- Publicist Tools -->
  <div class="staff-tools-viewing">

    <!-- Edit Publicist -->
    <j-button
      type="icon"
      help="Edit Publicist"
      icon="edit"
      @action="handleEdit"
    />

    <!-- Publicists Directory -->
    <j-button
      type="icon"
      help="Publicists Directory"
      icon="list"
      @action="handleIndex"
    />

  </div>
</template>

<script>

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapActions } = createNamespacedHelpers('publicists')

export default {
  name: 'PublicistTools',
  // -----------
  // Computed ==
  // -----------
  computed: {
    ...mapState([
      'publicist'
    ]),
    showActions () {
      return this.$route.name.startsWith('contributor-edit') || this.$route.name === 'staff-create'
    }
  },
  // ----------
  // Methods ==
  // ----------
  methods: {
    ...mapActions([
      'savePublicist'
    ]),
    handleIndex () {
      this.$router.push({ name: 'publicists-directory' })
    },
    handleEdit () {
      this.$dialog.open('publicist-form')
    }
  }
}
</script>
