<template>
  <div class="publicist-details">

    <!-- Publicist Information -->
    <j-card
      title="Contact Information"
      class="publicist-contact"
    >
      <j-card-text>
        <v-container
          fluid
          class="pa-0"
        >
          <v-row>
            <v-col>

              <!-- First Name -->
              <j-display label="First Name">
                {{ publicist.$.first_name }}
              </j-display>

            </v-col>
            <v-col>

              <!-- Last Name -->
              <j-display label="Last Name">
                {{ publicist.$.last_name }}
              </j-display>

            </v-col>
          </v-row>

          <v-row>
            <v-col>

              <!-- Email -->
              <j-display label="Email">
                <a :href="`mailto:${publicist.$.email}`">
                  <j-icon
                    icon="envelope"
                    :title="publicist.$.email"
                    class="mr-1"
                  />{{ publicist.$.email }}</a>
              </j-display>

            </v-col>
            <v-col>

              <!-- Phone -->
              <j-display label="Phone">
                {{ publicist.$.day_phone }}
              </j-display>

            </v-col>
          </v-row>

          <!-- Organization -->
          <j-display v-if="publicist.$.organization" label="Organization">
            {{ publicist.$.organization }}
          </j-display>

          <!-- Contact Notes -->
          <j-display v-if="publicist.$.contact_notes" label="Contact Notes">
            {{ publicist.$.contact_notes }}
          </j-display>
        </v-container>
      </j-card-text>
    </j-card>

  </div>
</template>

<script>

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapState } = createNamespacedHelpers('publicists')

// Components

export default {
  name: 'PublicistDetails',
  // -----------
  // Computed ==
  // -----------
  computed: {
    ...mapState([
      'publicist'
    ])
  }
}
</script>
