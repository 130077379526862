<template>

  <!-- Staff Record View -->
  <j-page
    :title="publicist.name || publicist.email"
    :loading="loading"
    fill
    id="publicist-record-view"
  >

    <!-- Actions -->
    <template #actions>

      <!-- Staff Tools -->
      <PublicistTools />

    </template>

    <!-- Left - Details -->
    <template #left>

      <!-- Publicist Details -->
      <PublicistDetails />

    </template>

    <!-- Right - Opps -->
    <template #right>

      <PublicistOpps />

    </template>

    <!-- Dialogs -->
    <template #dialogs>

      <!-- Edit Publicist -->
      <PublicistFormDialog />
  
    </template>  
  </j-page>
</template>

<script>

// Components
import PublicistFormDialog from '@/components/Publicists/PublicistFormDialog'
import PublicistDetails from '@/components/Publicists/PublicistDetails'
import PublicistOpps from '@/components/Publicists/PublicistOpps'
import PublicistTools from '@/components/Publicists/PublicistTools'

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapGetters, mapActions } = createNamespacedHelpers('publicists')

export default {
  name: 'PublicistView',
  // -------------
  // Components ==
  // -------------
  components: {
    PublicistFormDialog,
    PublicistDetails,
    PublicistOpps,
    PublicistTools
  },
  // -------------
  // Middleware ==
  // -------------
  beforeRouteEnter(to, _from, next) {
    next(vm => {
      // Get contact
      const slug = to.params.slug
      vm.fetchPublicist(slug)
      vm.fetchOpps({ slug })
    })
  },
  beforeRouteUpdate(to, from, next) {
    const slug = to.params.slug
    if (slug !== from.params.slug) {
      this.fetchPublicist(slug)
    }
    next()
  },
  // -----------
  // Metadata ==
  // -----------
  metaInfo() {
    return {
      title: this.publicist.name || this.publicist.email
    }
  },
  // -------------
  // Properties ==
  // -------------
  props: {
    slug: {
      type: String,
      default: null
    }
  },
  // -----------
  // Computed ==
  // -----------
  computed: {
    ...mapState([
      'publicist'
    ]),
    ...mapGetters([
      'loading'
    ])
  },
  // ----------
  // Methods ==
  // ----------
  methods: {
    ...mapActions([
      'fetchPublicist',
      'fetchOpps'
    ])
  }
}
</script>
